@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';













.Loader {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.Loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #2af3f3;
  animation: loader-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.Loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.Loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.Loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loader-animation {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
