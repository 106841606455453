@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';






















































































@import '@/assets/scss/_colors.scss';
.ScanCode_step {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $primaryText;
  margin: 0 0 20px 0;
}

.ScanCode_key {
  margin: 0 0 20px 0;
}

.ScanCode_keyTitle {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $primaryText;
  margin: 0 0 14px 0;
  text-align: left;
}

.ScanCode_qr {
  background: #1f4d64;
  width: 206px;
  height: 206px;
  margin: 0 auto 30px;
  border-radius: 4px;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.ScanCode_loader {
  height: 425px;
}
