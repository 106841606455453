.Google__authenticator {
  background-image: url('../../../../../assets/img/auth/G2FA-bg.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.Google__authenticatorSwitcher {
  margin: 15px 0 auto auto;

  .v-input {
    padding: 0;
    margin: 0;
  }

  .v-input--switch--inset .v-input--selection-controls__input {
    width: 38px;
    margin: 0;
  }

  .v-input--switch--inset .v-input--switch__track {
    width: 38px;
    height: 18px;
    background-color: rgba($primaryText, 0.14);
    color: $primaryText;
    border: 2px solid #2af3f3;
    transition: 0.25s all ease-in-out;
  }

  .v-input--switch__thumb {
    width: 11px;
    height: 11px;
  }

  .v-input--selection-controls__ripple {
    height: 25px;
    width: 25px;
  }

  .v-input--switch__thumb {
    transition: 0.25s all ease-in-out;
    background: $primaryLanding;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  }

  .v-input--is-label-active {
    .v-input--switch__thumb {
      background: $primaryText;
      box-shadow: 0px 7.2px 15.12px rgba(34, 39, 47, 0.24);
    }

    &.v-input--switch--inset {
      .v-input--switch__track {
        transition: 0.25s all ease-in-out;
        color: $primaryLanding !important;
        opacity: 1 !important;
        box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
      }
    }
  }
}

.Auth_stepsProgeress {
  max-width: 452px;
  width: 100%;
  height: 4px;
  border-radius: 26px;
  background: #0f1e30;
  position: relative;
  margin: 0 0 30px 0;

  &::before {
    transition: 0.25s all ease-in-out;
    content: '';
    width: 20%;
    height: 4px;
    position: absolute;
    left: 0;
    background: $primaryLanding;
    box-shadow: 0 0 10px rgba(42, 243, 243, 0.55);
    border-radius: 26px;
  }

  &-download_app {
    &:before {
      width: 33.33%;
    }
  }

  &-scan_code {
    &:before {
      width: 66.66%;
    }
  }

  &-confirm {
    &:before {
      width: 100%;
    }
  }
}

.Telegram__bot {
  //padding: 34px 0 0 0;
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;

  &Img {
    //width: 60px;
    //height: 60px;
    //margin: 0 20px 0 0;
    //position: relative;
    //background: #2d4d70;
    //border: 5px solid #2af3f3;
    //box-shadow: 0 0 10px rgb(42 243 243 / 55%);
    //border-radius: 50%;
    //padding: 10px;

    img {
      //width: 100%;
      //height: 100%;
      //object-fit: contain;
      //pointer-events: none;
    }
  }

  //h3 {
  //  font-weight: 500;
  //  font-size: 18px;
  //  line-height: 21px;
  //  letter-spacing: 0.04em;
  //  text-transform: uppercase;
  //  margin: 0 0 24px 0;
  //}

  a {
    //height: 38px;
    //padding: 8px 18px;
    //border-radius: 2px;
    //background: #2AF3F3;
    box-shadow: 0px 0px 8.1747px rgba(42, 243, 243, 0.55);
    //display: flex;
    //align-items: center;
    //color: #2D4D70;
    //letter-spacing: 0.04em;
    //text-transform: uppercase;
    //text-decoration: none;
    //font-weight: bold;
    //font-size: 12px;
    //line-height: 14px;
    //transition: 0.25s all ease-in-out;

    svg path {
      transition: 0.25s all ease-in-out;
    }

    //span {
    //  width: 21px;
    //  height: 21px;
    //  border-radius: 28px;
    //  background: #2D4D70;
    //  position: relative;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  margin: 0 8px 0 0;
    //}

    &:hover {
      background: #a5ffff;
      color: #2d4d70;

      svg path{
        fill: #a5ffff;
        stroke: #a5ffff;
      }
    }
  }
  //.Telegram__botContainer {
  //  display: flex;
  //  align-items: center;
  //}
}

.Telegram__botDelete {
  svg {
    margin: 0 0 0 4px;

    path {
      transition: 0.25s all ease-in-out;
    }
  }

  &:hover {
    background: #2AF3F3;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
    color: #2D4D70;

    svg path {
      fill: #2D4D70;
    }
  }
}