@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';






















































































































































@import '@/assets/scss/_colors.scss';

.Confirm_root {
  .Confirm_step {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $primaryText;
    margin: 0 0 20px 0;
  }

  .Confirm_stepLabel {
    display: flex;
    align-items: center;
    padding: 23px 20px;
    background: #1f4960;
    border-radius: 4px;
    max-width: 390px;
    margin: 0 auto 30px auto;
  }

  .Confirm_stepLabelDesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: 8px;
  }

  .Confirm_stepLabelDescTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $primaryLanding;
    margin: 0 0 10px 0;
  }

  .Confirm_stepLabelDescText {
    color: $primaryLanding;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
  }

  .Confirm_input {
    height: 30px;
    margin: 0 auto 80px;
    max-width: 390px;

    & > ul {
      padding: 0;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0 8px;
      list-style-type: none;
      & > li {
        padding-bottom: 6px;
        position: relative;

        &::before {
          content: '';
          width: 100%;
          position: absolute;
          height: 4px;
          bottom: 0;
          left: 0;
          background: $primaryLanding;
          box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
        }

        input {
          padding: 0;
          height: 30px;
          width: 100%;
          font-size: 28px;
          width: 100%;
          padding: 0;
          outline: none;
          color: $primaryLanding;
          font-weight: 600;
          border-radius: 0 !important;
          border: none !important;
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          -moz-appearance: textfield;
        }
      }
    }
  }

  .Confirm_btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 10px;
  }
}
