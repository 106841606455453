@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

























































@import '@/assets/scss/_colors.scss';
.DownloadApp_application {
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 84px;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  background: #1f4960;
  transition: 0.25s all ease-in-out;
  margin: 0 10px;
  path {
    transition: 0.25s all ease-in-out;
  }
  &:hover {
    background: #a5ffff;
    box-shadow: 0px 0px 10px rgb(42 243 243 / 55%);
    path {
      fill: #2d4d70;
    }
    .DownloadApp_applicationDescription {
      color: #2d4d70;
    }
  }
}
.DownloadApp_applicationDescription {
  display: flex;
  transition: 0.25s all ease-in-out;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 15px;
  font-size: 14px;
  line-height: 16px;
  color: $primaryLanding;
  span {
    margin: 6px 0 0 0;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
  }
}
.DownloadApp_applications {
  display: flex;
  justify-content: center;
  margin: 0 0 30px 0;
}
.DownloadApp_step {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $primaryText;
  margin: 0 0 20px 0;
}
.DownloadApp_description {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $primaryText;
}
